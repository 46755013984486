import React, { useState } from 'react'

export default function ModalStats({requests, users, utils, modalStateSwitch}) {

    const requestsCount = {
        new: requests.filter((r) => r.status === 'new').length,
        accepted: requests.filter((r) => r.status === 'accepted').length,
        done: requests.filter((r) => r.status === 'done').length
    }
    
    const [userIDs] = useState(() => {
        let arr = [];
        utils.forEach(ut => {
            Object.keys(ut.stats).forEach(k => arr.push(k));
        });
        return arr.filter(function(item, pos) {return arr.indexOf(item) === pos});
    });

    const [userList] = useState(() => {
        return users.filter(us => userIDs.includes(us._id));
    });

    function countTime(time) {
        // Перетворюємо різницю на секунди
        let totalSeconds = Math.floor(time / 1000);
    
        // Обчислюємо години, хвилини та секунди
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;
    
        // Повертаємо результат у форматі "Х год. Х хв Х сек"
        return `${hours.toString().padStart(2, 0)}:${minutes.toString().padStart(2, 0)}:${seconds.toString().padStart(2, 0)}`;
    }

    function getPrevTasks(user) {
        let prevTasks = 0;

        if (utils.length > 0) {
            utils.forEach((ut) => {
                if (ut.stats && ut.stats[user._id]) {
                    prevTasks += ut.stats[user._id].completed;
                }
            })
        }

        return prevTasks;
    }

    function getPrevTimeSpent(user) {
        let prevTimeSpent = 0;

        if (utils.length > 0) {
            utils.forEach((ut) => {
                if (ut.stats && ut.stats[user._id]) {
                    prevTimeSpent += ut.stats[user._id].timeSpent;
                }
            })
        }

        return prevTimeSpent;
    }

    return(
        <div className='absolute w-full h-full bg-black/40 flex justify-center items-center' onClick={() => {modalStateSwitch(false)}}>
            <div className='bg-white md:rounded-lg p-4 md:shadow-lg'>
                <div className='text-center font-bold pb-2'>СТАТИСТИКА</div>
                <div className='grid grid-cols-3 border-b-[1px] border-b-black/20'>
                    <div className='col-span-3 font-medium text-center border-b-[1px] border-b-black/20 pb-2'>Кількість задач</div>
                    <div className='text-center py-2'>Нові: <span className='font-bold text-sky-600'>{requestsCount.new}</span></div>
                    <div className='text-center py-2'>В роботі: <span className='font-bold text-sky-600'>{requestsCount.accepted}</span></div>
                    <div className='text-center py-2'>Виконано: <span className='font-bold text-sky-600'>{requestsCount.done}</span></div>
                </div>

                <div className='grid grid-cols-[2rem_12rem_6rem_6rem] gap-4 border-b-[1px] border-b-black/20 py-2'>
                    <div className='flex justify-center items-center leading-5 font-medium text-center'>№</div>
                    <div className='flex justify-center items-center leading-5 font-medium text-center'>ПІБ співробітника</div>
                    <div className='flex justify-center items-center leading-5 font-medium text-center'>Виконано задач</div>
                    <div className='flex justify-center items-center leading-5 font-medium text-center'>Витрачено часу</div>
                </div>
                <div className='flex flex-col'>
                {
                    userList.filter((us) => us.roles.it).sort((a, b) => b.priority - a.priority).map((us, index) => 
                        <div key={us.chatID} className='grid grid-cols-[2rem_12rem_6rem_6rem] gap-4 py-2 border-b-[1px] border-b-black/20 last:border-none'>
                            <div className='flex justify-center items-center leading-5'>{index + 1}</div>
                            <div className='flex justify-center items-center leading-5'>{us.name}</div>
                            <div className='flex justify-center items-center leading-5'>{getPrevTasks(us)}</div>
                            <div className='flex justify-center items-center leading-5'>{countTime(getPrevTimeSpent(us))}</div>
                        </div>
                    )
                }
                </div>
            </div>
        </div>
    )
}