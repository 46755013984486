import React, {useState, useEffect} from 'react';
import { DBData } from '../scripts/socket';
import Req from '../components/journal/Req';
import ModalStats from '../components/journal/ModalStats';

export default function Journal() {
	// Режим інтерфейсу
	const [intMode, setIntMode] = useState(() => {
		return localStorage.getItem('interface_mode') || 'light';
	});
	const intModeClasses = {
		light: {bg1: 'bg-white', bg2: 'bg-zinc-300', text: 'text-black', border: 'border-zinc-300', button: 'from-zinc-300 to-white border-white', accentText: 'text-sky-600', accentOutline: 'focus:outline-sky-600', accentBG: 'bg-sky-600'},
		dark1: {bg1: 'bg-zinc-900', bg2: 'bg-zinc-800', text: 'text-white', border: 'border-zinc-800', button: 'from-zinc-800 to-zinc-900 border-zinc-900', accentText: 'text-sky-600', accentOutline: 'focus:outline-sky-600', accentBG: 'bg-sky-600'},
		dark2: {bg1: 'bg-gray-900', bg2: 'bg-gray-800', text: 'text-white', border: 'border-gray-800', button: 'from-gray-800 to-gray-900 border-gray-900', accentText: 'text-sky-600', accentOutline: 'focus:outline-sky-600', accentBG: 'bg-sky-600'},
		dark3: {bg1: 'bg-[#061206]', bg2: 'bg-[#003417]', text: 'text-white', border: 'border-[#003417]', button: 'from-[#003417] to-[#061206] border-[#061206]', accentText: 'text-[#0a9400]', accentOutline: 'focus:outline-[#0a9400]', accentBG: 'bg-[#0a9400]'},
		dark4: {bg1: 'bg-[#12060e]', bg2: 'bg-[#470e35]', text: 'text-white', border: 'border-[#470e35]', button: 'from-[#470e35] to-[#12060e] border-[#12060e]', accentText: 'text-[#b02183]', accentOutline: 'focus:outline-[#b02183]', accentBG: 'bg-[#b02183]'},
		dark5: {bg1: 'bg-[#180606]', bg2: 'bg-[#440909]', text: 'text-white', border: 'border-[#440909]', button: 'from-[#440909] to-[#180606] border-[#180606]', accentText: 'text-[#ae0101]', accentOutline: 'focus:outline-[#ae0101]', accentBG: 'bg-[#ae0101]'}
	}

	// Дати
	const [today] = useState(() => {
		const today = new Date();
		const YYYY = today.getFullYear();
		const MM = (today.getMonth() + 1).toString().padStart(2, '0');
		const DD = today.getDate().toString().padStart(2, '0');
		return `${YYYY}-${MM}-${DD}`;
	});

	const [fromDate, setFromDate] = useState(today);
	const [toDate, setToDate] = useState(today);

	const handleFromDateChange = (e) => {
		setFromDate(e.target.value);
	};
	
	const handleToDateChange = (e) => {
		setToDate(e.target.value);
	};

	// Фільтри по імені та задачі
	const [reqFilter, setReqFilter] = useState();
	const [nameFilter, setNameFilter] = useState([]);

	const [requests, setRequests] = useState([]);
	const [users, setUsers] = useState([]);
	const [utils, setUtils] = useState([]);
	const [apteks, setApteks] = useState([]);

	const handleRequestFilterChange = (e) => {
		setReqFilter(e.target.value);
	}

	const handleNameFilterChange = (e) => {
		let name = e.target.value;

		// Якщо пуста строка - то повертаємо пустий масив
		if (name === '') {
			setNameFilter([]);
			return;
		}

		// Намагаэмось ынакше
		let filteredUsers = users.filter((us) => us.name.toLowerCase().includes(name.toLowerCase()));
		
		if (filteredUsers.length > 0) {
			setNameFilter(filteredUsers);
		} else {
			setNameFilter([-1]);
		}
	}

	// Модальне вікно зі статистикою
	const [modalState, setModalState] = useState(false);

	function modalStateSwitch(state) {
		setModalState(state);
	}

	const response = DBData(fromDate, toDate);

	useEffect(() => {
		if (response) {
			// console.log(response);
			const {requests: newRequests, users: newUsers, utils: newUtils, apteks: newApteks} = response;
			setRequests(newRequests.reverse());
			setUsers(newUsers);
			setUtils(newUtils);
			setApteks(newApteks);
		}
	}, [response]);

	function clearFilters() {
		setReqFilter('');
		setNameFilter('');

		setFromDate(today);
		setToDate(today);

		document.getElementById('inputTask').value = '';
		document.getElementById('inputName').value = '';
	}

	const handleIntModeChange = (val) => {
		localStorage.setItem('interface_mode', val);
		setIntMode(val);
	}

	// Ререндер, якщо реквести міняються
	useEffect(() => {}, [requests]);

	// Ререндер, якщо режим інтерфейсу міняється
	useEffect(() => {}, [intMode]);

	// Фільтрація реквестів
	const filteredRequests = requests.filter(req => (!reqFilter || req.msg.includes(reqFilter)) && (nameFilter.length === 0 || nameFilter.some(user => user.chatID === req.assigned)));

	return(
		<div className={`w-screen h-screen ${intModeClasses[intMode].bg1} duration-300`}>

			<div className='sm:flex md:hidden h-screen text-white font-bold text-[4rem] p-8 text-center items-center justify-center'>МОБІЛЬНА ВЕРСІЯ ЖУРНАЛУ В РОЗРОБЦІ</div>

			{modalState ? <ModalStats requests={requests} users={users} utils={utils} modalStateSwitch={modalStateSwitch}/> : ''}

			<div className='sm:hidden md:flex flex-col md:p-2 md:gap-2 lg:p-4 lg:gap-4 md:text-[0.75rem] lg:text-[1rem]'>

				{/* header */}
				<div className={`flex md:flex-row justify-between items-center ${intModeClasses[intMode].bg2} duration-300 px-4 py-4 rounded-lg`}>

					<div className="flex flex-col gap-">
						<div className={`md:leading-6 md:text-[1.125rem] lg:text-[1.5rem] font-bold ${intModeClasses[intMode].text}`}>Журнал IT-бота</div>
						<div className={`font-medium underline cursor-pointer ${intModeClasses[intMode].accentText} hover:text-opacity-75 duration-200`} onClick={() => {modalStateSwitch(true)}}>Подивитись статистику</div>
					</div>

					<div className="flex gap-4">
						<div className="flex flex-col gap-1">
							<div className={`md:text-[0.66rem] lg:text-[0.75rem] font-bold ${intModeClasses[intMode].text}`}>Кольорова схема:</div>
							<div className='flex md:gap-1 lg:gap-2'>
								<div className={`flex md:w-[1.5rem] lg:w-[2rem] aspect-square bg-gradient-to-tr from-50% to-50% ${intModeClasses.light.button} border-[0.2rem] rounded-full justify-center items-center cursor-pointer hover:opacity-70`} onClick={() => {handleIntModeChange('light')}}></div>
								<div className={`flex md:w-[1.5rem] lg:w-[2rem] aspect-square bg-gradient-to-tr from-50% to-50% ${intModeClasses.dark1.button} border-[0.2rem] rounded-full justify-center items-center cursor-pointer hover:opacity-70`} onClick={() => {handleIntModeChange('dark1')}}></div>
								<div className={`flex md:w-[1.5rem] lg:w-[2rem] aspect-square bg-gradient-to-tr from-50% to-50% ${intModeClasses.dark2.button} border-[0.2rem] rounded-full justify-center items-center cursor-pointer hover:opacity-70`} onClick={() => {handleIntModeChange('dark2')}}></div>
								<div className={`hidden flex md:w-[1.5rem] lg:w-[2rem] aspect-square bg-gradient-to-tr from-50% to-50% ${intModeClasses.dark3.button} border-[0.2rem] rounded-full justify-center items-center cursor-pointer hover:opacity-70`} onClick={() => {handleIntModeChange('dark3')}}></div>
								<div className={`hidden flex md:w-[1.5rem] lg:w-[2rem] aspect-square bg-gradient-to-tr from-50% to-50% ${intModeClasses.dark4.button} border-[0.2rem] rounded-full justify-center items-center cursor-pointer hover:opacity-70`} onClick={() => {handleIntModeChange('dark4')}}></div>
								<div className={`hidden flex md:w-[1.5rem] lg:w-[2rem] aspect-square bg-gradient-to-tr from-50% to-50% ${intModeClasses.dark5.button} border-[0.2rem] rounded-full justify-center items-center cursor-pointer hover:opacity-70`} onClick={() => {handleIntModeChange('dark5')}}></div>
							</div>
						</div>
						<div className="flex flex-col gap-1">
							<div className={`md:text-[0.66rem] lg:text-[0.75rem] font-bold ${intModeClasses[intMode].text} duration-300`}>Суть задачі:</div>
							<input type="text" className={`md:h-[1.5rem] lg:h-[2rem] px-2 py-1 rounded-md outline-none outline-offset-0 ${intModeClasses[intMode].accentOutline}`} id='inputTask' onChange={handleRequestFilterChange}/>
						</div>
						<div className="flex flex-col gap-1">
							<div className={`md:text-[0.66rem] lg:text-[0.75rem] font-bold ${intModeClasses[intMode].text} duration-300`}>ПІБ співробітника:</div>
							<input type="text" className={`md:h-[1.5rem] lg:h-[2rem] px-2 py-1 rounded-md outline-none outline-offset-0 ${intModeClasses[intMode].accentOutline}`} id='inputName' onChange={handleNameFilterChange}/>
						</div>
						<div className="flex flex-col gap-1">
							<div className={`md:text-[0.66rem] lg:text-[0.75rem] font-bold ${intModeClasses[intMode].text} duration-300`}>Період від:</div>
							<input type="date" className={`md:h-[1.5rem] lg:h-[2rem] px-2 rounded-md outline-none outline-offset-0 ${intModeClasses[intMode].accentOutline}`} value={fromDate} onChange={handleFromDateChange}/>
						</div>
						<div className="flex flex-col gap-1">
							<div className={`md:text-[0.66rem] lg:text-[0.75rem] font-bold ${intModeClasses[intMode].text} duration-300`}>Період до:</div>
							<input type="date" className={`md:h-[1.5rem] lg:h-[2rem] px-2 rounded-md outline-none outline-offset-0 ${intModeClasses[intMode].accentOutline}`} value={toDate} onChange={handleToDateChange}/>
						</div>
						<div className='flex gap-4'>
							<div className={`${intModeClasses[intMode].accentBG} hover:bg-opacity-75 px-4 py-2 text-white flex justify-center text-center items-center leading-4 rounded-lg cursor-pointer  duration-300`} onClick={clearFilters}>Скинути</div>
						</div>
					</div>
				</div>

				{/* table */}
				<div className='md:text-[0.75rem] lg:text-[1rem]'>
					{/* table header */}
					<div className={`grid md:grid-cols-[3rem_7rem_7rem_1fr_7rem_7rem_7rem_7rem_10rem] lg:grid-cols-[6rem_10rem_10rem_1fr_10rem_10rem_10rem_10rem_12rem] md:gap-4 lg:gap-8 font-bold text-wrap ${intModeClasses[intMode].bg2} ${intModeClasses[intMode].text} px-4 lg:py-4 md:py-2 md:rounded-t-lg overflow-y-auto duration-300`} style={{ scrollbarGutter: 'stable' }}>
						<div className='flex justify-center text-center items-center leading-4'>Номер задачі</div>
						<div className='flex justify-center text-center items-center leading-4'>Дата створення задачі</div>
						<div className='flex justify-center text-center items-center leading-4'>Постановник задачі</div>
						<div className='flex justify-center text-center items-center leading-4'>Суть задачі</div>
						<div className='flex justify-center text-center items-center leading-4'>ПІБ ІТ</div>
						<div className='flex justify-center text-center items-center leading-4'>Дата прийняття задачі</div>
						<div className='flex justify-center text-center items-center leading-4'>Створення - закриття</div>
						<div className='flex justify-center text-center items-center leading-4'>Прийняття - закриття</div>
						<div className='flex justify-center text-center items-center leading-4'>Статус</div>
					</div>
					<div className={`flex flex-col md:max-h-[calc(100vh-9.5rem)] lg:max-h-[calc(100vh-12.5rem)] overflow-y-auto md:rounded-b-lg border-[4px] pb-1 ${intModeClasses[intMode].border} ${intModeClasses[intMode].text} duration-300`} style={{ scrollbarGutter: 'stable' }}>
						{filteredRequests.length > 0 ? 
							filteredRequests.map((req) => 
								<Req key={req._id} reqData={req} users={users} apteks={apteks} intMode={intMode}/>
							)
							:
							<div className='py-4 font-bold text-center'>За вашим запитом задач не знайдено</div>
						}
					</div>
				</div>

			</div>
		</div>
	)
}