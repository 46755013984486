import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Journal from './pages/Journal';
import Sender from './pages/Sender';
import Form from './pages/Form';

export default function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Journal/>}/>
				<Route path="/sender" element={<Sender/>}/>
				<Route path="/form" element={<Form/>}/>
			</Routes>
		</Router>
	);
};