import React, {useState, useEffect} from 'react'

export default function Req({reqData, users, apteks, intMode}) {

    const [expanded, setExpanded] = useState(false);

	const intModeClasses = {
		light: {border: 'border-b-black/20', accentBG: 'bg-sky-600/10', accentBGHover: 'hover:bg-sky-600/10'},
		dark1: {border: 'border-b-zinc-200/20', accentBG: 'bg-sky-600/10', accentBGHover: 'hover:bg-sky-600/10'},
		dark2: {border: 'border-b-sky-400/20', accentBG: 'bg-sky-600/10', accentBGHover: 'hover:bg-sky-600/10'},
		dark3: {border: 'border-b-[#0a9400]/20', accentBG: 'bg-[#0a9400]/10', accentBGHover: 'hover:bg-[#0a9400]/10'},
		dark4: {border: 'border-b-[#b02183]/20', accentBG: 'bg-[#b02183]/10', accentBGHover: 'hover:bg-[#b02183]/10'},
        dark5: {border: 'border-b-[#b02020]/20', accentBG: 'bg-[#b02020]/10', accentBGHover: 'hover:bg-[#b02020]/10'}
	}

    function convertDateAsNeeded(timestamp) {
        let date = new Date(timestamp);
        let DD = date.getDate().toString().padStart(2, 0);
        let MM = (date.getMonth() + 1).toString().padStart(2, 0);
        let YYYY = date.getFullYear().toString().padStart(2, 0);
        let hh = date.getHours().toString().padStart(2, 0);
        let mm = date.getMinutes().toString().padStart(2, 0);
        let ss = date.getSeconds().toString().padStart(2, 0);

        return `${DD}.${MM}.${YYYY} ${hh}:${mm}:${ss}`
    }

    function countTime(start, end) {
        // Обчислюємо різницю в мілісекундах
        let difference = end - start;
    
        // Перетворюємо різницю на секунди
        let totalSeconds = Math.floor(difference / 1000);
    
        // Обчислюємо години, хвилини та секунди
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;
    
        // Формуємо результат у форматі "Х год. Х хв Х сек"
        return (hours > 0 ? `${hours} год ` : '') + (minutes > 0 ? `${minutes} хв ` : '') + (seconds > 0 ? `${seconds} сек` : '');
    }

    function drawStatus() {
        let statuses = {
            new: <div className='max-w-max font-medium text-white bg-red-600/70 px-4 py-1 md:rounded-md'>Нова</div>,
            accepted: <div className='max-w-max font-medium text-white bg-sky-600/70 px-4 py-1 md:rounded-md'>Прийнята</div>,
            notIT: <div className='max-w-max font-medium text-white bg-yellow-600/70 px-4 py-1 md:rounded-md'>Задача не IT</div>,
            prog: <div className='max-w-max font-medium text-white bg-orange-600/70 px-4 py-1 md:rounded-md'>Програмісту</div>,
            doneByIT: <div className='max-w-max font-medium text-white bg-green-700/70 px-4 py-1 md:rounded-md'>Виконана</div>,
            doneByCC: <div className='max-w-max font-medium text-white bg-lime-600/70 px-4 py-1 md:rounded-md'>Виконана (КЦ)</div>,
            doneByProg: <div className='max-w-max font-medium text-white bg-teal-600/70 px-4 py-1 md:rounded-md'>Виконана (Прог)</div>,
            unknown: <div className='max-w-max font-medium text-white bg-purple-600/70 px-4 py-1 md:rounded-md'>Помилка статусу</div>,
        }

        if (reqData.status === 'new') {
            if (reqData.isNotIT) {
                return statuses.notIT;
            }

            if (reqData.isProg) {
                return statuses.prog;
            }

            if (!reqData.isNotIT && !reqData.isProg) {
                return statuses.new;
            } else {
                return statuses.unknown;
            }
        } else if (reqData.status === 'accepted') {
            if (reqData.isNotIT) {
                return statuses.notIT;
            }
                        
            if (reqData.isProg) {
                return statuses.prog;
            }

            if (!reqData.isNotIT && !reqData.isProg) {
                return statuses.accepted;
            } else {
                return statuses.unknown;
            }
        } else if (reqData.status === 'done') {
            if (reqData.isNotIT) {
                return statuses.doneByCC;
            }
                        
            if (reqData.isProg) {
                return statuses.doneByProg;
            }

            if (!reqData.isNotIT && !reqData.isProg) {
                return statuses.doneByIT;
            } else {
                return statuses.unknown;
            }
        } else {
            return statuses.unknown;
        }
    }

    // Ререндер, якщо режим інтерфейсу міняється
	useEffect(() => {}, [intMode]);

    // Ререндер, якщо міняється стан розширення
	useEffect(() => {}, [expanded]);

    function getNameByChatID(chatID) {
        let user = users.find(us => us.chatID === chatID);
        return user ? user.name : chatID;
    }

    function getSender(sender) {
        if (typeof sender === 'number') {
            let senderUser = getNameByChatID(sender);
            if (senderUser) {
                return senderUser;
            } else {
                return sender;
            }
        } else if (typeof sender === "string") {
            if (sender.length <= 4) {
                let apteka = apteks.find(apt => apt._id === sender);
                if (apteka) {
                    return apteka.name;
                } else {
                    return sender;
                }
            } else {
                return sender;
            }
        }
        return null;
    }

    function isSenderApteka(sender) {
        if (typeof sender === 'number') {
            return false
        } else if (typeof sender === "string") {
            if (sender.length <= 4) {
                return true
            } else {
                return false;
            }
        }
        return false;
    }

    const getApteka = (sender) => {
        let apteka = apteks.find(apt => apt._id === sender);
        if (apteka) {
            return apteka;
        } else {
            return sender;
        }
    }

    return(
        <>
            {expanded ? 
                <div className={`grid grid-cols-4 md:gap-4 lg:gap-8 py-4 px-4 duration-200 cursor-pointer border-b-[1px] ${intModeClasses[intMode].accentBG} ${intModeClasses[intMode].border} items-center`} onClick={() => setExpanded(false)} >
                    <div className='flex flex-col'>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>Дата створення: <span className={`${intMode === 'light' ? 'text-black' : 'text-white'} font-medium`}>{reqData.timestamps.creation ? convertDateAsNeeded(reqData.timestamps.creation) : ''}</span></div>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>Дата прийняття: <span className={`${intMode === 'light' ? 'text-black' : 'text-white'} font-medium`}>{reqData.timestamps.acception ? convertDateAsNeeded(reqData.timestamps.acception) : ''}</span></div>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>Дата переводу: <span className={`${intMode === 'light' ? 'text-black' : 'text-white'} font-medium`}>{reqData.timestamps.reassignment ? convertDateAsNeeded(reqData.timestamps.reassignment) : ''}</span></div>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>Дата закриття: <span className={`${intMode === 'light' ? 'text-black' : 'text-white'} font-medium`}>{reqData.timestamps.completion ? convertDateAsNeeded(reqData.timestamps.completion) : ''}</span></div>
                    </div>
                    <div className='flex flex-col'>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>Створення - закриття: <span className={`${intMode === 'light' ? 'text-black' : 'text-white'} font-medium`}>{reqData.timestamps.creation && reqData.timestamps.completion ? countTime(reqData.timestamps.creation, reqData.timestamps.completion) : ''}</span></div>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>Створення - прийняття: <span className={`${intMode === 'light' ? 'text-black' : 'text-white'} font-medium`}>{reqData.timestamps.creation && reqData.timestamps.acception ? countTime(reqData.timestamps.creation, reqData.timestamps.acception) : ''}</span></div>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>Прийняття - закриття/перевод: <span className={`${intMode === 'light' ? 'text-black' : 'text-white'} font-medium`}>{reqData.reassigned || reqData.isNotIT ? reqData.timestamps.acception && reqData.timestamps.reassignment ? countTime(reqData.timestamps.acception, reqData.timestamps.reassignment) : '' : reqData.timestamps.acception && reqData.timestamps.completion ? countTime(reqData.timestamps.acception, reqData.timestamps.completion) : ''}</span></div>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>Перевод - закриття: <span className={`${intMode === 'light' ? 'text-black' : 'text-white'} font-medium`}>{reqData.reassigned ? reqData.timestamps.reassignment && reqData.timestamps.completion ? countTime(reqData.timestamps.reassignment, reqData.timestamps.completion) : '' : ''}</span></div>
                    </div>
                    <div className='flex flex-col'>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>Номер задачі: <span className={`${intMode === 'light' ? 'text-black' : 'text-white'} font-medium`}>{parseInt(reqData._id)}</span></div>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>Суть задачі:</div>
                        <div className={`${intMode === 'light' ? 'text-black': 'text-white'} font-medium`}>{reqData.msg}</div>
                    </div>
                    <div className='flex flex-col'>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>{isSenderApteka(reqData.sender) ? 'Аптека' : 'Постановник'}: <span className={`${intMode === 'light' ? 'text-black' : 'text-white'} font-medium`}>{getSender(reqData.sender)}</span></div>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>ПІБ IT: <span className={`${intMode === 'light' ? 'text-black' : 'text-white'} font-medium`}>{getNameByChatID(reqData.assigned)}</span></div>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'}`}>Переведено на: <span className={`${intMode === 'light' ? 'text-black' : 'text-white'} font-medium`}>{getNameByChatID(reqData.reassigned)}</span></div>
                        <div className={`${intMode === 'light' ? 'text-black/50' : 'text-white/40'} flex gap-2 items-center`}>Статус: {drawStatus()}</div>
                    </div>
                </div>
                :
                <div className={`grid md:grid-cols-[3rem_7rem_7rem_1fr_7rem_7rem_7rem_7rem_10rem] lg:grid-cols-[6rem_10rem_10rem_1fr_10rem_10rem_10rem_10rem_12rem] md:gap-4 lg:gap-8 py-4 px-3 duration-200 cursor-pointer border-b-[1px] ${intModeClasses[intMode].accentBGHover} ${intModeClasses[intMode].border} items-center`} onClick={() => setExpanded(true)}>
                    <div className='text-center'>{parseInt(reqData._id)}</div>
                    <div className='text-center'>{reqData.timestamps.creation ? convertDateAsNeeded(reqData.timestamps.creation) : ''}</div>
                    <div className='text-center'>{getSender(reqData.sender)}</div>
                    <div className='text-wrap'>{reqData.msg}</div>
                    <div className='text-center'>{getNameByChatID(reqData.assigned)}</div>
                    <div className='text-center'>{reqData.timestamps.acception ? convertDateAsNeeded(reqData.timestamps.acception) : ''}</div>
                    <div className='text-center'>{reqData.timestamps.completion ? countTime(reqData.timestamps.creation, reqData.timestamps.completion) : ''}</div>
                    <div className='text-center'>{reqData.timestamps.acception && reqData.timestamps.completion ? countTime(reqData.timestamps.acception, reqData.timestamps.completion) : ''}</div>
                    <div className='flex justify-center'>{drawStatus()}</div>
                </div>
            }
        </>
    )
}