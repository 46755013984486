import React, {useState} from 'react';
import { SendRequest } from '../scripts/socket';

export default function Sender() {
	const [requestMessage, setRequestMessage] = useState('');
    const [notification, setNotification] = useState('');

	const handleInputChange = (e) => {
		setRequestMessage(e.target.value)
	}

	const generateRequest = async () => {
		if (requestMessage !== '') {
			const LSSender = localStorage.getItem('sender');

            let url = window.location.href;
            const urlObj = new URL(url);
            const aptekaID = urlObj.searchParams.get('id');
            const URLSender = aptekaID ? decodeURIComponent(aptekaID) : undefined;

			let request = {
				"msg": requestMessage,
				"sender": LSSender ? LSSender : URLSender ? URLSender : null,
			}
	
			try {
				await SendRequest(request);
				setNotification('success');
				setTimeout(() => setNotification(''), 5000);
				setRequestMessage(''); // Очищення текстової області після відправки
			} catch (error) {
				setNotification('error');
				setTimeout(() => setNotification(''), 5000);
			}
		}
	}

	return (
		<div className="absolute flex flex-col gap-4 justify-center items-center w-full h-full p-4 duration-300">
			<div className='flex flex-col w-[30rem] rounded-lg shadow-lg'>
				<div className='bg-sky-600 p-4 rounded-t-lg text-white font-bold'>Відправити повідомлення в IT-бот</div>
				<div className='bg-zinc-200 p-4 pb-0 flex h-[10rem]'><textarea className='outline-none w-full h-full rounded-md p-2' style={{resize: 'none'}} value={requestMessage} onChange={handleInputChange}/></div>
				<div className='bg-zinc-200 p-4 rounded-b-lg flex justify-center gap-4'>
					<button className='bg-sky-600 px-4 py-2 rounded-md text-white font-bold hover:bg-sky-700 duration-300' onClick={generateRequest}>Відправити</button>
				</div>
			</div>
			{notification === 'success' ? 
				<div className='flex w-[30rem]'><div className='bg-green-600 p-2 rounded-l-lg text-[2rem] flex items-center'>✅</div><div className='bg-zinc-200 w-full p-4 rounded-r-lg'>Ваше звернення зареєстровано. Співробітники IT-відділу зв'яжуться з Вами найближчим часом.<br/><br/>Якщо з Вами не зв'язались протягом години, будь ласка, зверніться до колцентру за телефоном: +380931700911.</div></div>
				: notification === 'error' ? 
				<div className='flex w-[30rem]'><div className='bg-orange-600 p-2 rounded-l-lg text-[2rem] flex items-center'>⚠️</div><div className='bg-zinc-200 w-full p-4 rounded-r-lg'>При подачі Вашого звернення сталась помилка.<br/><br/>Будь ласка, зверніться до колцентру за телефоном: +380931700911.</div></div> 
				: ''
			}
		</div>
	);
}