import { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';

const ENDPOINT = 'https://maxraimer.com:3030';

export function DBData (fromDate, toDate) {
    const [response, setResponse] = useState('');

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT, {
            transports: ['websocket', 'polling'],
            withCredentials: true,
            extraHeaders: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        // Еміт сигнал на сервер з параметрами fromDate і toDate
        const fetchData = () => {socket.emit('requestDBData', { fromDate, toDate })};

        fetchData();
        const interval = setInterval(fetchData, 500);

        // Слухати відповідь з сервера
        socket.on('DBData', data => {
            setResponse(data);
        });

        return () => {
            clearInterval(interval);
            socket.disconnect();
        };
    }, [fromDate, toDate]);

    return response;
}

export async function SendRequest(request) {
    const socket = socketIOClient(ENDPOINT, {
        transports: ['websocket', 'polling'],
        withCredentials: true,
        extraHeaders: {
            'Authorization': 'Bearer YOUR_TOKEN',  // If you use token-based auth
            'X-Requested-With': 'XMLHttpRequest'
        }
    });

    return new Promise((resolve, reject) => {
        socket.emit('newRequest', request, (result) => {
            if (result) {
                resolve(result);
            } else {
                reject(new Error('Помилка під час відправки запиту'));
            }
            socket.disconnect();
        });
    });
}