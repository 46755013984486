import React, { useState, useEffect } from 'react';
import { SendRequest } from '../scripts/socket';

export default function Form() {
	const [requestMessage, setRequestMessage] = useState('');
    const [notification, setNotification] = useState('');
    const [selectedKassas, setSelectedKassas] = useState([]);
    const [anotherKassa, setAnotherKassa] = useState('');
    const [chosenCategory, setChosenCategory] = useState('');

    const handleCategoryChange = (e) => {
        setChosenCategory(e.target.value)
    }

    const handleKassaChoice = (kassaNum) => {
        setSelectedKassas(prevSelectedKassas => {
            if (prevSelectedKassas.includes(kassaNum)) {
                return prevSelectedKassas.filter(num => num !== kassaNum);
            } else {
                return [...prevSelectedKassas, kassaNum];
            }
        });
    }

    const handleInputChange = (e) => {
		setRequestMessage(e.target.value)
	}

    const handleAnotherKassa = (e) => {
		setAnotherKassa(e.target.value)
    }

	const generateRequest = async () => {
		if (requestMessage !== '') {
			const LSSender = localStorage.getItem('sender');

            let kassas = selectedKassas.length > 0 ? selectedKassas.join(', ') : '';
            let category = chosenCategory ? chosenCategory : '';

            let url = window.location.href;
            const urlObj = new URL(url);
            const title = urlObj.searchParams.get('title');
            const URLSender = title ? decodeURIComponent(title) : undefined;

            if (anotherKassa) {
                kassas += anotherKassa;
            } 

			let request = {
				"msg": requestMessage,
                "kassas": kassas,
                "category": category,
				"sender": LSSender ? LSSender : URLSender ? URLSender : '',
				"status": "new",
				"timestamps": {
				  "creation": null,
				  "acception": null,
				  "reassignment": null,
				  "completion": null
				},
				"assigned": null,
				"reassigned": null,
				"isNotIT": false
			}
	
			// try {
			// 	await SendRequest(request);
			// 	setNotification('success');
			// 	setTimeout(() => setNotification(''), 5000);
			// 	setRequestMessage(''); // Очищення текстової області після відправки
			// } catch (error) {
			// 	setNotification('error');
			// 	setTimeout(() => setNotification(''), 5000);
			// }
            try {
				console.log(request);
				setNotification('success');
				setTimeout(() => setNotification(''), 5000);
				setRequestMessage(''); // Очищення текстової області після відправки
			} catch (error) {
				setNotification('error');
				setTimeout(() => setNotification(''), 5000);
			}
		}
	}

    useEffect(() => {}, [selectedKassas]);

    return(
        <div className="absolute flex flex-col gap-4 justify-center items-center w-full h-full p-4 duration-300">
			<div className='flex flex-col w-[24rem] rounded-lg shadow-lg'>
				<div className='bg-sky-600 p-4 rounded-t-lg text-white font-bold'>Відправити повідомлення в IT-бот</div>
				<div className='bg-zinc-200 p-4 flex flex-col gap-4'>
                    <div className='flex flex-col gap-1'>
                        <span>Оберіть касу (каси), де виникла проблема</span>
                        <div className='grid grid-cols-3 gap-2'>
                            <div className={`p-2 rounded-md border-2 ${selectedKassas.includes(1) ? 'bg-sky-600/50 border-sky-600' : 'bg-white/50 border-zinc-400'} text-center cursor-pointer select-none`} onClick={() => handleKassaChoice(1)}>1</div>
                            <div className={`p-2 rounded-md border-2 ${selectedKassas.includes(2) ? 'bg-sky-600/50 border-sky-600' : 'bg-white/50 border-zinc-400'} text-center cursor-pointer select-none`} onClick={() => handleKassaChoice(2)}>2</div>
                            <div className={`p-2 rounded-md border-2 ${selectedKassas.includes(3) ? 'bg-sky-600/50 border-sky-600' : 'bg-white/50 border-zinc-400'} text-center cursor-pointer select-none`} onClick={() => handleKassaChoice(3)}>3</div>
                            <div className={`p-2 rounded-md border-2 ${selectedKassas.includes(4) ? 'bg-sky-600/50 border-sky-600' : 'bg-white/50 border-zinc-400'} text-center cursor-pointer select-none`} onClick={() => handleKassaChoice(4)}>4</div>
                            <div className={`p-2 rounded-md border-2 ${selectedKassas.includes(5) ? 'bg-sky-600/50 border-sky-600' : 'bg-white/50 border-zinc-400'} text-center cursor-pointer select-none`} onClick={() => handleKassaChoice(5)}>5</div>
                            <div className={`p-2 rounded-md border-2 ${selectedKassas.includes(10) ? 'bg-sky-600/50 border-sky-600' : 'bg-white/50 border-zinc-400'} text-center cursor-pointer select-none`} onClick={() => handleKassaChoice(10)}>Матеріальна</div>
                            <input className='col-span-3 p-2 rounded-md outline-none outline-offset-0 focus:outline-sky-600' placeholder='інша каса' onChange={handleAnotherKassa}/>
                        </div>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <span>З чим пов'язана ваша проблема?</span>
                        <select className='p-2 rounded-md outline-none outline-offset-0 focus:outline-sky-600' onChange={handleCategoryChange}>
                            <option disabled selected>Оберіть варіант</option>
                            <option value='Інтернет'>Інтернет</option>
                            <option value='Марія'>Касовий апарат "Марія"</option>
                            <option value='Електрона'>Електрона каса та принтер для друку чеків</option>
                            <option value='Термінал'>Термінал</option>
                            <option value='EKKA'>Торгова програма EKKA</option>
                            <option value='EKKA Mini'>Мобільна програма EKKA Mini</option>
                            <option value='Принтер'>Принтер або друк документів</option>
                            <option value='Відеоспостереження'>Відеоспостереження</option>
                            <option value='Інше'>Інше</option>
                        </select>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <span>Опишіть проблему</span>
                        <div className='bg-zinc-200 flex h-[10rem]'><textarea className='outline-none w-full h-full rounded-md p-2' style={{resize: 'none'}} value={requestMessage} onChange={handleInputChange}/></div>
                    </div>
                </div>
				<div className='bg-zinc-200 p-4 pt-0 rounded-b-lg flex justify-center gap-4'>
					<button className='bg-sky-600 px-4 py-2 rounded-md text-white font-bold hover:bg-sky-700 duration-300' onClick={generateRequest}>Відправити</button>
				</div>
			</div>
			{notification === 'success' ? 
				<div className='flex w-[30rem]'><div className='bg-green-600 p-2 rounded-l-lg text-[2rem] flex items-center'>✅</div><div className='bg-zinc-200 w-full p-4 rounded-r-lg'>Ваше звернення зареєстровано. Співробітники IT-відділу зв'яжуться з Вами найближчим часом.<br/><br/>Якщо з Вами не зв'язались протягом години, будь ласка, зверніться до колцентру за телефоном: +380931700911.</div></div>
				: notification === 'error' ? 
				<div className='flex w-[30rem]'><div className='bg-orange-600 p-2 rounded-l-lg text-[2rem] flex items-center'>⚠️</div><div className='bg-zinc-200 w-full p-4 rounded-r-lg'>При подачі Вашого звернення сталась помилка.<br/><br/>Будь ласка, зверніться до колцентру за телефоном: +380931700911.</div></div> 
				: ''
			}
		</div>
    )
}